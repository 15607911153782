<div id="_Eco-Turn-Summary_" class="py-2 w-full flex flex-col flex-wrap box-border">
  <div class="py-2 ism-gold text-1.5">Economic Summary for Turn {{ this.session.turnEdit() }}</div>

  @if (!this.session.isEconomicsActive) {
    <div class="alert alert-danger">Economics are not active.</div>
  } @else if (this.session.isEconomicsActive) {
    <turn-selector id="turnSelector" [firstTurn]="race!.turnActivated" [campaignTurn]="campaignTurn" (onTurnChange)="updateEconomicTurn($event)"></turn-selector>
    <div id="_ECONOMIC-SUMMARY-CENTER" class="w-full flex flex-col text-1.125">
      @if (summarizedTransactions) {
        <div class="w-full flex flex-col">
          <div class="w-full flex">
            <table id="_AFTER-INCOME_" class="mr-2 my-0.5 w-1/2">
              <tr>
                <td class="m-0 p-0 text-center ism-gold" colspan="2">Income</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/income/population" routerLinkActive="ngActiveLinkGreen">Population Income</a>
                </td>
                <td class="text-right whitespace-nowrap">{{ (summarizedTransactions["popIncomeTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/income/trade" routerLinkActive="ngActiveLinkGreen">Trade Income</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["tradeIncomeTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/income/ship-lease" routerLinkActive="ngActiveLinkGreen">Ship Lease</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["leaseIncomeTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/income/ship-sales" routerLinkActive="ngActiveLinkGreen">Ship Sales</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["shipSaleTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/income/other-income" routerLinkActive="ngActiveLinkGreen">Other Income</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["otherIncomeTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr>
                <td class="m-0 px-2 py-0 text-center ism-gold">Total Income</td>
                <td class="m-0 px-2 py-0 text-right ism-gold">{{ incomeTotal | number: "1.2-2" }} MC</td>
              </tr>
            </table>

            <table id="_AFTER-EXPENSES_" class="ml-2 my-0.5 w-1/2 border-0">
              <tr>
                <td class="m-0 p-2 text-center ism-gold" colspan="2">Expenses</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/maintenance" routerLinkActive="ngActiveLinkGreen">Maintenance</a>
                </td>
                <td class="text-right whitespace-nowrap">{{ (summarizedTransactions["maintenanceTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/construction" routerLinkActive="ngActiveLinkGreen">Construction</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["constructionTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/industrial-expansion" routerLinkActive="ngActiveLinkGreen">Industrial Expansion</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["industrialTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/colonization" routerLinkActive="ngActiveLinkGreen">Colonization</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["colonizationTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/troops" routerLinkActive="ngActiveLinkGreen">Planetary Control Forces</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["troopsTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/economic-research" routerLinkActive="ngActiveLinkGreen">Economic Level Research</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["researchTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left whitespace-nowrap">
                  <a routerLink="/race/economics/expenses/project-research" routerLinkActive="ngActiveLinkGreen">Technical Item Development</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["techItemTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="text-left">
                  <a routerLink="/race/economics/expenses/other-expenses" routerLinkActive="ngActiveLinkGreen">Other Expenses</a>
                </td>
                <td class="text-right">{{ (summarizedTransactions["OtherExpenseTotal"] || {}).amount || 0 | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td class="m-0 p-2 text-center ism-gold">Total Expenses</td>
                <td class="m-0 p-2 text-right ism-gold">{{ expensesTotal | number: "1.2-2" }} MC</td>
              </tr>
              <tr class="data">
                <td [ngClass]="{ 'text-green-400': remainder >= 0, 'text-red-400': remainder < 0 }" class="m-0 p-2 text-center">Remainder</td>
                <td [ngClass]="{ 'text-green-400': remainder >= 0, 'text-red-400': remainder < 0 }" class="text-right font-semibold">{{ remainder | number: "1.2-2" }} MC</td>
              </tr>
            </table>
          </div>

          <table id="_REMAINDER_" class="my-0.5 border-0">
            @if (canPerformAction) {
              <tr>
                <td colspan="2" class="text-center">
                  <br />
                  @if (canSubmit) {
                    <button type="button" class="btn btn-default" (click)="economicsReady()">Economics are ready!</button>
                  }
                  @if (canChangeReadyFlag) {
                    <span>
                      <button type="button" class="btn btn-default" (click)="economicsNotReady()">Wait, I changed my mind!</button>
                    </span>
                  }
                </td>
              </tr>
            }
          </table>
        </div>
      }
    </div>
  }
</div>
