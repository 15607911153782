import { Component, OnInit, Signal, WritableSignal, computed, effect, inject, signal } from "@angular/core";
import { Dialog, DialogConfig } from "@angular/cdk/dialog";
import { ComponentType } from "@angular/cdk/overlay";
import { map, Subject, tap } from "rxjs";

import { SessionService } from "../../services/session.service";
import { TransactionService } from "../../services/transaction.service";

import { Campaign } from "../../interfaces/campaign";
import { DialogData } from "../../interfaces/dialog-data";
import { Race } from "../../interfaces/race";
import { KeyedMiniTransaction, Transaction } from "../../interfaces/transaction";
import { AreYouSure2Component } from "../../shared/are-you-sure2/are-you-sure2.component";

@Component({
  template: ''
})
export class TransactionBaseComponent implements OnInit {
  public session = inject(SessionService);
  protected transactionService = inject(TransactionService);
  protected dialog = inject(Dialog);

  race: Race;
  campaign: Campaign;
  currentTurn: number;

  // extending class MUST override transactionType
  transactionType: string = "unknown";
  popIncomeTotal: WritableSignal<number> = signal(0);
  incomeTotal: number;
  turnOfComponent: number;

  updatedTransaction: Subject<Transaction> = new Subject<Transaction>();
  deletedTransaction: Subject<Transaction> = new Subject<Transaction>();

  allTransactions: WritableSignal<Transaction[]> = signal<Transaction[]>([]);
  transactions: WritableSignal<Transaction[]> = signal<Transaction[]>([]);
  transactionById: { [key: string]: Transaction; } = {};

  summary: Signal<KeyedMiniTransaction> = computed<KeyedMiniTransaction>(
    () => this.transactionService.buildSummaryFromTransactions(this.allTransactions())
  );

  total = computed(() => this.transactions().reduce(
    (sum, transaction) => (sum + transaction.amount), 0
  ));

  expensesTotal: Signal<number> = computed(() => {
    let summary = this.summary();
    return (
      ((summary['maintenanceTotal'] || {})['amount'] || 0) +
      ((summary['constructionTotal'] || {})['amount'] || 0) +
      ((summary['industrialTotal'] || {})['amount'] || 0) +
      ((summary['colonizationTotal'] || {})['amount'] || 0) +
      ((summary['troopsTotal'] || {})['amount'] || 0) +
      ((summary['researchTotal'] || {})['amount'] || 0) +
      ((summary['techItemTotal'] || {})['amount'] || 0) +
      ((summary['otherExpenseTotal'] || {})['amount'] || 0)
      - ((summary[this.transactionType + 'Total'] || {})['amount'] || 0)
    );
  });

  constructor () {
    this.session.iAmBusy();
    this.race = this.session.getRace() as Race;
    this.campaign = this.session.getCampaign() as Campaign;
    this.currentTurn = this.campaign.turn;
    this.turnOfComponent = this.session.turnEdit();

    this.updatedTransaction.subscribe(updatedTransaction => {
      // A New transaction will be added by _id
      // An Edited transaction will be updated by _id
      // the revised transactions will update the transactions signal
      this.transactionById[updatedTransaction._id] = updatedTransaction;
      this.transactions.set(Object.values(this.transactionById));
    });

    this.deletedTransaction.subscribe(deletedTransaction => {
      // A deleted transaction will be removed by _id
      // the revised transactions will update the transactions signal
      delete this.transactionById[deletedTransaction._id];
      this.transactions.set(Object.values(this.transactionById));
    });

    effect(() => {
      let summary = this.summary();
      this.popIncomeTotal.set((summary['popIncomeTotal'] || { amount: 0 })['amount']);
      this.incomeTotal =
        this.popIncomeTotal() +
        ((summary['tradeIncomeTotal'] || {})['amount'] || 0) +
        ((summary['leaseIncomeTotal'] || {})['amount'] || 0) +
        ((summary['shipSaleTotal'] || {})['amount'] || 0) +
        ((summary['otherIncomeTotal'] || {})['amount'] || 0);
    }, { allowSignalWrites: true });

    this.session.iAmNotBusy();
  };

  filterTransactions (transactions: Transaction[]) {
    if (transactions.length > 0 && this.transactionType !== "unknown") {
      transactions = transactions.filter(t => t.type === this.transactionType);
    }
    return transactions;
  };

  ngOnInit (): void {
    this.session.iAmBusy();
    this.transactionService.getOrBuildTransactions(this.race, this.turnOfComponent).pipe(
      tap(transactions => this.allTransactions.set(transactions)),
      map(transactions => this.filterTransactions(transactions)),
    ).subscribe(
      transactions => {
        this.transactions.set(transactions);
        this.transactionById = transactions.reduce((hash, t) => (hash[t._id] = t, hash), {});
        this.session.iAmNotBusy();
      }
    );
  };

  newTransaction (): Transaction {
    return {
      campaignId: this.campaign._id,
      raceId: this.race._id,
      type: this.transactionType,
      turn: this.session.turnEdit(),
      quantity: 1,
      amount: 0
    };
  };

  buildDataDialogConfig (documentName: string = undefined, document: any = undefined): DialogConfig<unknown> {
    if (!documentName) {
      documentName = "Transaction";
    }
    if (!document) {
      document = this.newTransaction();
    }
    return {
      // width: '65%',
      // height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: documentName,
        document: Object.assign({}, document)
      }
    };
  };

  // extending class SHOULD either
  //   - override buildEditDataDialog OR
  //   - override buildDataDialogConfig
  buildEditDataDialog (documentName: string, document: any): DialogConfig<unknown> {
    return this.buildDataDialogConfig(documentName, document);
  };

  saveTransaction (transaction: Transaction) {
    this.session.iAmBusy();
    this.transactionService.saveTransaction(transaction).subscribe(
      (savedTransaction) => {
        this.updatedTransaction.next(savedTransaction);
        this.session.transactionSaved();
        this.session.iAmNotBusy();
      }
    );
  };

  // extending class SHOULD override processEditResult if it is not a simple saveTransaction
  processEditResult (result: { [key: string]: any; }) {
    let transaction: Transaction = result['document'] as Transaction;
    this.saveTransaction(transaction);
  };

  openTransactionDialog (
    documentName: string,
    transaction: Transaction,
    editComponent: ComponentType<any>,
    config: DialogConfig<unknown> = undefined
  ) {
    if (!config) {
      config = this.buildEditDataDialog(documentName, transaction);
    }
    const dialogRef = this.dialog.open<DialogData>(editComponent, config);

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.processEditResult(result);
      }
    });
  };

  // extending class SHOULD either
  //   - override buildDeleteDataDialog OR
  //   - override buildDataDialogConfig
  buildDeleteDataDialog (documentName: string, primaryObject: any): DialogConfig<unknown> {
    return this.buildDataDialogConfig(documentName, primaryObject);
  };

  deleteTransaction (transaction: Transaction) {
    this.session.iAmBusy();
    this.transactionService.deleteTransactionById(transaction._id).subscribe(
      () => {
        this.deletedTransaction.next(transaction);
        this.session.transactionDeleted();
        this.session.iAmNotBusy();
      }
    );
  }

  processDeleteResult (result) {
    let primaryObject = result['document'];
    this.deleteTransaction(primaryObject);
  };

  openDeleteTransactionDialog (
    documentName: string,
    transaction: any,
    deleteComponent: ComponentType<any> = undefined,
    config: DialogConfig<unknown> = undefined
  ) {
    if (!deleteComponent) {
      deleteComponent = AreYouSure2Component;
    }
    if (!config) {
      config = this.buildDeleteDataDialog(documentName, transaction);
    }
    const dialogRef = this.dialog.open<string>(deleteComponent, config);

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.processDeleteResult(result);
      }
    });
  };
};
